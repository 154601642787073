import React, { useEffect, useRef, useState } from 'react'
import { Header, HeaderBox, Footer } from '../components/imports'
import { APP_NAME, WEBROOT } from '../constants/general'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Categories from '../class/Categories'
import { AllCategories } from '../components/Categories'
import { ArticleCategorie } from '../components/Articles'
import gla_slider from '../Json/slider.js'
import { useTranslation } from 'react-i18next'

function Categorie() {

  const { t } = useTranslation()

  const { url } = useParams()

  const navigate = useNavigate()

  const [categorie, setCategorie] = useState(false)

  const [souscategorie, setSousCategorie] = useState(false)

  const [annonces, setAnnonces] = useState('')

  const [options, setOptions] = useState({})

  const [limit, setLimit] = useState(1)

  const [key, setKey] = useState(0)

  const [prixMin, setPrixMin] = useState(0)

  const [boxFilter, showBoxFilter] = useState(false)

  const getCategorieByUrl = async (limit_var, loading, options = {}) => {

    if (loading) setCategorie(false)

    let results = await Categories.getCategorieByUrl(url, limit_var, options)

    if (results.success) {

      setCategorie(results.data)

      setAnnonces(results.annonces)

      document.title = results.data.name + " - " + APP_NAME

      setSousCategorie(results.souscategories)

      setTimeout(() => {

        new gla_slider('#gla_slider', {
          childs: 6,
          items: '.art',
          speed: 2,
          margin: 5
        })

        observateur()

      }, 50)

    } else {

      navigate('/404')

    }

  }
  
  const voirPlus = () => {

    setLimit(limit + 1)

    getCategorieByUrl(limit + 1, false, options)

  }

  const observateur = () => {

    const voirPlusbtn = document.querySelector('.voir_plus_btn')

    if (voirPlusbtn) {

      const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                voirPlusbtn.click()
              }
            });
          },
          {
            root: null, // viewport par défaut
            threshold: 1.0, // l'élément doit être complètement visible
          }
        );

        observer.observe(voirPlusbtn);

    }

  }

  const filter = (e, bool) => {

    e.preventDefault()

    let input = e.target

    let options_obj = {
      prix_min: input.prix_min.value,
      prix_max: input.prix_max.value,
      promotion: input.promotion.checked,
      faitmain: input.faitmain.checked,
      emballage: input.emballage.checked,
      occasion: input.occasion.value
    }

    getCategorieByUrl(1, true, options_obj)

    setOptions(options_obj)

    showBoxFilter(false)

    setLimit(1)

  }

  const sortBy = (sort_by) => {

    let sortArticles = []

    if (sort_by == "moins_cher") sortArticles = annonces.sort((a, b) => a.prix - b.prix)
    if (sort_by == "plus_cher") sortArticles = annonces.sort((a, b) => b.prix - a.prix)
    if (sort_by == "ancien") sortArticles = annonces.sort((a, b) => a.idA - b.idA)
    if (sort_by == "recent") sortArticles = annonces.sort((a, b) => b.idA - a.idA)

    setKey(currentKey => currentKey + 1)

    setAnnonces(sortArticles)

  }

  useEffect((url) => {

    setLimit(1)

    getCategorieByUrl(1, true)

    document.title = "Categorie - " + APP_NAME

    document.body.classList.remove('mn')

    window.scrollTo(0, 0)

  }, [url])

  return (
    <div className='pos-r'>

      <Header />

      <HeaderBox />

      {

        categorie

          ?

          <>

            <div className='bg1 mt30 mb30 c pos-r'>

              <h1 className='h1 cl3'>{categorie.name}</h1>

              <div className='flex flex-end jc-end m_flex m_mr_10'>
                <div className='brca p10 mb30 bg7 flex-end pos-r down mr10'>

                  <div className='flex ai-center m_flex fz09'>
                    <div className='h fw6 cl8 hover-cl3'>
                      <svg fill="#000000" width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M22,4.5a1,1,0,0,1-1,1H12a1,1,0,0,1,0-2h9A1,1,0,0,1,22,4.5Zm-1,4H14a1,1,0,0,0,0,2h7a1,1,0,0,0,0-2Zm0,5H16a1,1,0,0,0,0,2h5a1,1,0,0,0,0-2Zm0,5H18a1,1,0,0,0,0,2h3a1,1,0,0,0,0-2ZM6,2A1,1,0,0,0,5,3V18.586L3.707,17.293a1,1,0,0,0-1.414,1.414l3,3a1,1,0,0,0,1.416,0l3-3a1,1,0,0,0-1.414-1.414L7,18.586V3A1,1,0,0,0,6,2Z" /></svg>
                    </div>
                  </div>

                  <div className='filtrer'>
                    <span className='hover-cl3 cl8 d-block btn' onClick={e => sortBy('recent')}>{t('t404')}</span>
                    <span className='hover-cl3 cl8 d-block btn' onClick={e => sortBy('ancien')}>{t('t405')}</span>
                    <span className='hover-cl3 cl8 d-block btn' onClick={e => sortBy('moins_cher')}>{t('t406')}</span>
                    <span className='hover-cl3 cl8 d-block btn' onClick={e => sortBy('plus_cher')}>{t('t407')}</span>
                  </div>

                </div>

                <div className='brca p10 mb30 bg7'>
                  <div className='ai-center fz09'>
                    <p className='h fw6 cl8 hover-cl3 c_point' onClick={e => showBoxFilter(!boxFilter)}>
                      <svg fill="#000000" width="24px" height="24px" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"><g data-name="Layer 24" id="Layer_24"><path d="M10,12H6a1,1,0,0,1-1-1V7A1,1,0,0,1,6,6h4a1,1,0,0,1,1,1v4A1,1,0,0,1,10,12ZM7,10H9V8H7Z" /><path d="M6,8.47V11h4V8.47a2.11,2.11,0,0,1-2,1A2.11,2.11,0,0,1,6,8.47Z" /><path d="M21,15.47V18h4V15.47a2.11,2.11,0,0,1-2,1A2.11,2.11,0,0,1,21,15.47Z" /><path d="M6,22.47V25h4V22.47a2.11,2.11,0,0,1-2,1A2.11,2.11,0,0,1,6,22.47Z" /><path d="M29,10H10a1,1,0,0,1,0-2H29a1,1,0,0,1,0,2Z" /><path d="M6,10H3A1,1,0,0,1,3,8H6a1,1,0,0,1,0,2Z" /><path d="M10,26H6a1,1,0,0,1-1-1V21a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1v4A1,1,0,0,1,10,26ZM7,24H9V22H7Z" /><path d="M6,24H3a1,1,0,0,1,0-2H6a1,1,0,0,1,0,2Z" /><path d="M29,24H10a1,1,0,0,1,0-2H29a1,1,0,0,1,0,2Z" /><path d="M25,19H21a1,1,0,0,1-1-1V14a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1v4A1,1,0,0,1,25,19Zm-3-2h2V15H22Z" /><path d="M21,17H3a1,1,0,0,1,0-2H21a1,1,0,0,1,0,2Z" /><path d="M29,17H25a1,1,0,0,1,0-2h4a1,1,0,0,1,0,2Z" /></g></svg>
                    </p>
                  </div>
                </div>
              </div>

              {

                souscategorie

                  ?

                  <div className="grid6 m_grid2 ta-center fz08 mb30 special" id='gla_slider'>
                    {souscategorie.map(element => <AllCategories data={element} key={element.idC} />)}
                  </div>

                  :

                  <div className='flex jc-center mt50 mb50'>

                    <div className="lds-dual-ring col"></div>

                  </div>

              }

              <div className='flex ai-start'>

                {

                  boxFilter

                  &&

                  <div className='filter_mask'>

                    <div className='col-4 bg1 p20 m_m_10'>

                      <h3 className='M fw5 fz12 mb20 m_ml_10'>{t('t408')}</h3>

                      <form className='form mt30 mr20 m_p10' method='POST' onSubmit={e => filter(e, true)}>

                        <div className='grid2 mb20'>

                          <label>
                            {t('t409')}
                            <input type='number' name='prix_min' className='col-98 mb0' min={0} defaultValue={prixMin} onChange={e => setPrixMin(e.target.value)} />
                          </label>

                          <label>
                            {t('t410')}
                            <input type='number' name='prix_max' className='col-9 mb0' min={prixMin} defaultValue={0} />
                          </label>

                        </div>

                        <label>
                          <input type='checkbox' name='promotion' className='mr10' />
                          {t('t411')}
                        </label>

                        <label>
                          <input type='checkbox' name='faitmain' className='mr10' />
                          {t('t412')}
                        </label>

                        <label>
                          <input type='checkbox' name='emballage' className='mr10' />
                          {t('t81')}
                        </label>

                        <label className='fz15'>{t('t413')}</label>
                        <select name="occasion">

                          <option value='0'>{t('t168')}</option>
                          <option value='1'>{t('t169_1')}</option>
                          <option value='2'>{t('t169')}</option>
                          <option value='3'>{t('t170')}</option>
                          <option value='4'>{t('t171')}</option>
                          <option value='5'>{t('t172')}</option>
                          <option value='6'>{t('t173')}</option>
                          <option value='7'>{t('t174')}</option>
                          <option value='8'>{t('t175')}</option>
                          <option value='9'>{t('t176')}</option>
                          <option value='10'>{t('t177')}</option>
                          <option value='11'>{t('t178')}</option>
                          <option value='12'>{t('t180')}</option>
                          <option value='13'>{t('t181')}</option>
                          <option value='14'>{t('t182')}</option>
                          <option value='15'>{t('t183')}</option>
                          <option value='16'>{t('t183_2')}</option>
                          <option value='17'>{t('t179')}</option>

                        </select>

                        <input type='submit' value={t('t408')} className='btn bg3 cl1' />

                      </form>

                      <span className='exit_btn bg3 cl1' onClick={e => showBoxFilter(false)}>x</span>

                    </div>

                  </div>

                }

                {

                  annonces

                    ?

                    annonces.length > 0

                      ?

                      <>

                        <div className='cards mb30 articles col-10 grid5' key={key}>

                          {annonces.map(element => <ArticleCategorie data={element} key={element.idA} />)}

                        </div>

                      </>

                      :

                      <p className='d-block ta-center'>{t('t414')}</p>

                    :

                    <div className='flex jc-center mt50 mb50 m_flex'>
                      <div className="lds-dual-ring col"></div>
                    </div>

                }

              </div>

              {

                annonces.length >= 15 && annonces.length % 15 == 0

                &&

                <span className='voir_plus_btn' onClick={voirPlus}></span>

              }

            </div>

          </>

          :
          <div className='flex jc-center mt40 mb40 m_flex'>
            <div className="lds-dual-ring col"></div>
          </div>

      }


      <Footer />
    </div>
  )
}

export default Categorie